"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RolePermissionsAdditionForm = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var material_1 = require("@mui/material");
var react_1 = require("react");
var react_hook_form_1 = require("react-hook-form");
var constants_1 = require("../../../../../constants");
var store_1 = require("../../../../../store");
var actions_1 = require("../../../../snackbar/store/actions");
var rolesApi_1 = require("../../../store/api/rolesApi");
var RolePermissionsAdditionForm = function (_a) {
    var id = _a.id, isDisabled = _a.isDisabled, userRolePermissions = _a.userRolePermissions;
    var dispatch = (0, store_1.useAppDispatch)();
    var rolePermissions = (0, rolesApi_1.useFetchRolePermissionsQuery)();
    var _b = (0, rolesApi_1.useCreateRolePermissionMutation)(), createRolePermissions = _b[0], createRoleResults = _b[1];
    var rolePermissionOptions = (0, react_1.useMemo)(function () {
        var _a;
        if (userRolePermissions && rolePermissions.data) {
            var names_1 = new Set(userRolePermissions === null || userRolePermissions === void 0 ? void 0 : userRolePermissions.map(function (v) { return v.id; }));
            return rolePermissions.data.filter(function (v) { return !names_1.has(v.id); });
        }
        return (_a = rolePermissions.data) !== null && _a !== void 0 ? _a : [];
    }, [userRolePermissions, rolePermissions.data]);
    var _c = (0, react_hook_form_1.useForm)({
        defaultValues: {
            permissionsIds: [],
        },
    }), control = _c.control, handleSubmit = _c.handleSubmit, isDirty = _c.formState.isDirty, reset = _c.reset;
    (0, react_1.useEffect)(function () {
        if (createRoleResults.isSuccess) {
            dispatch((0, actions_1.showSnackbar)({
                snackbarType: "info",
                snackbarMessage: constants_1.alertMessages.addPermissionSuccess,
            }));
        }
    }, [createRoleResults.isSuccess]);
    var handleAddRolePermissionsSubmit = function (values) {
        createRolePermissions(__assign(__assign({}, values), { id: id }));
        reset({}, { keepDefaultValues: true });
    };
    return ((0, jsx_runtime_1.jsxs)("form", __assign({ className: "selectLayout", onSubmit: handleSubmit(handleAddRolePermissionsSubmit) }, { children: [(0, jsx_runtime_1.jsx)(react_hook_form_1.Controller, { render: function (_a) {
                    var field = _a.field;
                    return ((0, jsx_runtime_1.jsx)(material_1.Select, __assign({ multiple: true }, field, { "data-id": "selectRolePermissions", disabled: isDisabled || !(rolePermissionOptions === null || rolePermissionOptions === void 0 ? void 0 : rolePermissionOptions.length), placeholder: "\u0412\u044B\u0431\u0435\u0440\u0438\u0442\u0435 \u0440\u0430\u0437\u0440\u0435\u0448\u0435\u043D\u0438\u0435", size: "small", sx: {
                            width: 400,
                        } }, { children: rolePermissionOptions === null || rolePermissionOptions === void 0 ? void 0 : rolePermissionOptions.map(function (option) { return ((0, jsx_runtime_1.jsx)(material_1.MenuItem, __assign({ value: option.id }, { children: option.description }), option.id)); }) })));
                }, name: "permissionsIds", control: control }), (0, jsx_runtime_1.jsx)(material_1.Button, __assign({ color: "primary", "data-id": "addRolePermissionsButton", disabled: !isDirty || !rolePermissionOptions.length, size: "small", type: "submit", variant: "outlined" }, { children: "\u0414\u043E\u0431\u0430\u0432\u0438\u0442\u044C" }))] })));
};
exports.RolePermissionsAdditionForm = RolePermissionsAdditionForm;
