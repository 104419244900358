"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useSearchApproversQuery = exports.useFetchApproverGroupsQuery = exports.useFetchApproverGroupQuery = exports.useEditApproverGroupMutation = exports.useDeleteApproverGroupMutation = exports.useCreateApproverGroupMutation = exports.approversApi = void 0;
var react_1 = require("@reduxjs/toolkit/query/react");
var baseQuery_1 = require("../../../../api/baseQuery");
exports.approversApi = (0, react_1.createApi)({
    reducerPath: "approvers",
    baseQuery: baseQuery_1.baseQuery,
    tagTypes: ["ApproverGroup", "ApproverGroups"],
    endpoints: function (builder) { return ({
        createApproverGroup: builder.mutation({
            query: function (body) { return ({
                url: "/api/v1/approvergroup",
                method: "POST",
                body: body,
            }); },
            invalidatesTags: ["ApproverGroups"],
            transformResponse: function (result) { return result.payload; },
        }),
        deleteApproverGroup: builder.mutation({
            query: function (id) { return ({
                url: "/api/v1/approvergroup/".concat(id),
                method: "DELETE",
            }); },
            invalidatesTags: ["ApproverGroup", "ApproverGroups"],
            transformResponse: function (result) { return result.payload; },
        }),
        editApproverGroup: builder.mutation({
            query: function (_a) {
                var body = _a.body, id = _a.id;
                return ({
                    url: "/api/v1/approvergroup/".concat(id),
                    method: "PUT",
                    body: body,
                });
            },
            invalidatesTags: ["ApproverGroup", "ApproverGroups"],
            transformResponse: function (result) { return result.payload; },
        }),
        fetchApproverGroup: builder.query({
            query: function (id) { return ({
                url: "/api/v1/approvergroup/".concat(id),
                method: "GET",
            }); },
            providesTags: ["ApproverGroup"],
            transformResponse: function (result) { return result.payload; },
        }),
        fetchApproverGroups: builder.query({
            query: function (params) { return ({
                url: "/api/v1/approvergroup".concat(params),
                method: "GET",
            }); },
            providesTags: ["ApproverGroups"],
            transformResponse: function (result) { return ({
                rows: result.payload,
                total: result.meta.total,
            }); },
        }),
        searchApprovers: builder.query({
            query: function (name) { return ({
                url: "/api/v1/approver/search?maxSize=20&name=".concat(name),
                method: "GET",
            }); },
            transformResponse: function (result) {
                var _a, _b;
                return ({
                    options: (_a = result.payload) !== null && _a !== void 0 ? _a : [],
                    isOverflowed: (_b = result.meta.isOverflowed) !== null && _b !== void 0 ? _b : true,
                });
            },
        }),
    }); },
});
exports.useCreateApproverGroupMutation = exports.approversApi.useCreateApproverGroupMutation, exports.useDeleteApproverGroupMutation = exports.approversApi.useDeleteApproverGroupMutation, exports.useEditApproverGroupMutation = exports.approversApi.useEditApproverGroupMutation, exports.useFetchApproverGroupQuery = exports.approversApi.useFetchApproverGroupQuery, exports.useFetchApproverGroupsQuery = exports.approversApi.useFetchApproverGroupsQuery, exports.useSearchApproversQuery = exports.approversApi.useSearchApproversQuery;
