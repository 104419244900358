"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useUpdateUserMutation = exports.useFetchUserRolesQuery = exports.useFetchUserQuery = exports.useFetchUsersQuery = exports.useDeleteUserRoleMutation = exports.useDeleteUserMutation = exports.useAddUserRoleMutation = exports.useCreateUserMutation = exports.usersApi = void 0;
var react_1 = require("@reduxjs/toolkit/query/react");
var baseQuery_1 = require("../../../../api/baseQuery");
exports.usersApi = (0, react_1.createApi)({
    reducerPath: "userList",
    baseQuery: baseQuery_1.baseQuery,
    tagTypes: ["User", "UserList", "UserRoles"],
    endpoints: function (builder) { return ({
        createUser: builder.mutation({
            query: function (body) { return ({
                url: "/api/v1/user",
                method: "POST",
                body: body,
            }); },
            invalidatesTags: ["UserList"],
            transformResponse: function (result) { return result.payload; },
        }),
        deleteUser: builder.mutation({
            query: function (id) { return ({
                url: "/api/v1/user/".concat(id),
                method: "DELETE",
            }); },
            invalidatesTags: ["UserList"],
        }),
        deleteUserRole: builder.mutation({
            query: function (_a) {
                var id = _a.id, rolesIds = _a.rolesIds;
                return ({
                    url: "/api/v1/user/".concat(id, "/roles?rolesIds=").concat(rolesIds),
                    method: "DELETE",
                });
            },
            invalidatesTags: ["UserRoles"],
        }),
        addUserRole: builder.mutation({
            query: function (_a) {
                var id = _a.id, rolesIds = _a.rolesIds;
                return ({
                    url: "/api/v1/user/".concat(id, "/roles"),
                    body: { rolesIds: rolesIds },
                    method: "POST",
                });
            },
            invalidatesTags: ["UserRoles"],
            transformResponse: function (result) { return result.payload; },
        }),
        fetchUser: builder.query({
            query: function (id) { return ({
                url: "/api/v1/user/".concat(id),
            }); },
            providesTags: ["User"],
            transformResponse: function (result) { return result.payload; },
        }),
        fetchUserRoles: builder.query({
            query: function (id) { return ({
                url: "/api/v1/user/".concat(id, "/roles"),
            }); },
            providesTags: ["UserRoles"],
            transformResponse: function (result) { return result.payload; },
        }),
        fetchUsers: builder.query({
            query: function (params) { return ({
                url: "/api/v1/user".concat(params),
            }); },
            providesTags: ["UserList"],
            transformResponse: function (result) { return ({
                rows: result.payload,
                total: result.meta.total,
            }); },
        }),
        updateUser: builder.mutation({
            query: function (_a) {
                var body = _a.body, id = _a.id;
                return ({
                    url: "/api/v1/user/".concat(id),
                    method: "PUT",
                    body: body,
                });
            },
            invalidatesTags: ["User"],
        }),
    }); },
});
exports.useCreateUserMutation = exports.usersApi.useCreateUserMutation, exports.useAddUserRoleMutation = exports.usersApi.useAddUserRoleMutation, exports.useDeleteUserMutation = exports.usersApi.useDeleteUserMutation, exports.useDeleteUserRoleMutation = exports.usersApi.useDeleteUserRoleMutation, exports.useFetchUsersQuery = exports.usersApi.useFetchUsersQuery, exports.useFetchUserQuery = exports.usersApi.useFetchUserQuery, exports.useFetchUserRolesQuery = exports.usersApi.useFetchUserRolesQuery, exports.useUpdateUserMutation = exports.usersApi.useUpdateUserMutation;
