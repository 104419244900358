"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var material_1 = require("@mui/material");
var react_1 = require("react");
var react_router_1 = require("react-router");
var routes_1 = require("../../../../navigation/routes");
var S = __importStar(require("./style"));
function AccessDeniedPage() {
    var history = (0, react_router_1.useHistory)();
    var goToMain = (0, react_1.useCallback)(function () {
        history.push(routes_1.routes.monitoringEvents);
    }, []);
    var goToLogin = (0, react_1.useCallback)(function () {
        history.push(routes_1.routes.login);
    }, []);
    return ((0, jsx_runtime_1.jsxs)(S.Container, { children: [(0, jsx_runtime_1.jsx)("p", { children: "\u041D\u0435\u0434\u043E\u0441\u0442\u0430\u0442\u043E\u0447\u043D\u043E \u043F\u0440\u0430\u0432 \u0434\u043B\u044F \u0432\u044B\u043F\u043E\u043B\u043D\u0435\u043D\u0438\u044F \u0434\u0430\u043D\u043D\u043E\u0433\u043E \u0434\u0435\u0439\u0441\u0442\u0432\u0438\u044F." }), (0, jsx_runtime_1.jsxs)(material_1.Grid, __assign({ container: true, spacing: 0, direction: "column", alignItems: "flex-start", justifyContent: "space-around" }, { children: [(0, jsx_runtime_1.jsx)(material_1.Button, __assign({ onClick: goToMain, size: "small", variant: "outlined" }, { children: "\u041F\u0435\u0440\u0435\u0439\u0442\u0438 \u043D\u0430 \u0433\u043B\u0430\u0432\u043D\u044B\u0439 \u044D\u043A\u0440\u0430\u043D" })), (0, jsx_runtime_1.jsx)(material_1.Button, __assign({ style: { marginTop: "0.5%" }, onClick: goToLogin, size: "small", variant: "outlined" }, { children: "\u041F\u0435\u0440\u0435\u0439\u0442\u0438 \u043D\u0430 \u0441\u0442\u0440\u0430\u043D\u0438\u0446\u0443 \u0430\u0432\u0442\u043E\u0440\u0438\u0437\u0430\u0446\u0438\u0438" }))] }))] }));
}
exports.default = AccessDeniedPage;
