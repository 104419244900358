"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RiskIndicatorsTable = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var material_1 = require("@mui/material");
var table_container_1 = require("../../../../ud-ui/components/table-container");
var S = __importStar(require("./style"));
var RiskIndicatorsTable = function (props) {
    if (!props.data) {
        return (0, jsx_runtime_1.jsx)("div", __assign({ className: "loadingLayout" }, { children: "\u041D\u0435\u0442 \u0434\u0430\u043D\u043D\u044B\u0445" }));
    }
    var _a = props.data, rows = _a.rows, subTitles = _a.subTitles, titles = _a.titles;
    return ((0, jsx_runtime_1.jsx)(table_container_1.TableContainer, { children: (0, jsx_runtime_1.jsxs)(material_1.Table, { children: [(0, jsx_runtime_1.jsx)(material_1.TableHead, { children: (0, jsx_runtime_1.jsx)(material_1.TableRow, { children: titles.map(function (title, titleIndex) { return ((0, jsx_runtime_1.jsxs)(material_1.TableCell, { children: [title, subTitles[titleIndex] ? (0, jsx_runtime_1.jsxs)(S.SubTitle, { children: [" ", subTitles[titleIndex]] }) : ""] }, title)); }) }) }), (0, jsx_runtime_1.jsx)(material_1.TableBody, { children: rows.map(function (item, rowIndex) { return ((0, jsx_runtime_1.jsx)(material_1.TableRow, { children: item.map(function (value, i) { return ((0, jsx_runtime_1.jsx)(material_1.TableCell, { children: value }, "".concat(titles[i], "-").concat(rowIndex, "-").concat(i))); }) }, rowIndex)); }) })] }) }));
};
exports.RiskIndicatorsTable = RiskIndicatorsTable;
