"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.settingsRoutes = void 0;
var routes_1 = require("../../../../../../navigation/routes");
exports.settingsRoutes = [
    {
        title: "Пользователи",
        requestUrl: "/user",
        to: routes_1.routes.userList,
    },
    {
        title: "Роли и права",
        requestUrl: "/role",
        to: routes_1.routes.roleList,
    },
    {
        title: "Риск-факторы",
        requestUrl: "/riskfactor",
        to: routes_1.routes.riskFactorList,
    },
    {
        title: "Контрагенты на подписке",
        requestUrl: "/contractoronsubscription",
        to: routes_1.routes.contractorOnSub,
    },
    {
        title: "Эксперты",
        requestUrl: "/experts",
        to: routes_1.routes.expertList,
    },
    {
        title: "Потребители",
        requestUrl: "/consumer",
        to: routes_1.routes.consumerList,
    },
    {
        title: "Списки согласующих",
        requestUrl: "/approver",
        to: routes_1.routes.approversGroupList,
    },
];
