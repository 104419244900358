"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CONSUMER_TERRORIST = void 0;
exports.CONSUMER_TERRORIST = [
    {
        id: "legacyRealEstateName",
        isTechnical: false,
        title: "Объект недвижимости",
    },
    {
        id: "legacyRealEstateId",
        isTechnical: true,
        title: "СИД объекта недвижимости",
    },
    {
        id: "totalArea",
        isTechnical: false,
        title: "Площадь",
    },
    {
        id: "issuesCount",
        isTechnical: false,
        title: "Количество замечаний",
    },
    {
        id: "courtCaseNumber",
        isTechnical: false,
        title: "№ судебного дела",
    },
    {
        id: "courtCaseId",
        isTechnical: true,
        title: "СИД судебного дела",
    },
];
