"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TableContainer = void 0;
var system_1 = require("@mui/system");
var theme_1 = require("../../../../theme");
exports.TableContainer = (0, system_1.styled)("div")(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  box-shadow: 0 0 0 1px ", ";\n  border-radius: ", "px;\n  overflow-y: auto;\n"], ["\n  width: 100%;\n  box-shadow: 0 0 0 1px ", ";\n  border-radius: ", "px;\n  overflow-y: auto;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.palette.divider;
}, theme_1.blockBorderRadius);
var templateObject_1;
