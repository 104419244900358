"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.userRoutes = exports.userTableFiltersValues = void 0;
var edite_user_page_1 = require("../../ui/pages/edite-user-page");
var user_roles_page_1 = require("../../ui/pages/user-roles-page");
__exportStar(require("./schema"), exports);
exports.userTableFiltersValues = {
    email: {
        id: "email",
        label: "Почта",
        value: "",
    },
    login: {
        id: "login",
        label: "Логин",
        value: "",
    },
    name: {
        id: "name",
        label: "ФИО",
        value: "",
    },
};
exports.userRoutes = [
    {
        Component: edite_user_page_1.EditUserPage,
        label: "Основные параметры",
        permission: "userWrite",
        tabId: "profile",
    },
    {
        Component: user_roles_page_1.UserRolesPage,
        label: "Роли",
        permission: "userWrite",
        tabId: "roles",
    },
];
