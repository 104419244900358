"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getExternalMdmMessageData = void 0;
var getExternalMdmMessageData = function (value) {
    try {
        var parsedData = JSON.parse(value);
        return {
            data: JSON.stringify(parsedData, null, 2),
            identifier: parsedData.Id,
        };
    }
    catch (_a) {
        return {
            data: "",
            identifier: "",
        };
    }
};
exports.getExternalMdmMessageData = getExternalMdmMessageData;
