"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.responsibilities = void 0;
exports.responsibilities = [
    {
        responsibility: "companyRead",
        method: ["get"],
        url: ["/company"],
    },
    {
        responsibility: "contractorRead",
        method: ["get"],
        url: ["/consumer"],
    },
    {
        responsibility: "riskFactorRead",
        method: ["get"],
        url: ["/riskfactor", "/riskfactorvaluetype"],
    },
    {
        responsibility: "riskFactorWrite",
        method: ["post", "delete", "put"],
        url: ["/riskfactor"],
    },
    {
        responsibility: "monitoringEventRead",
        method: ["get"],
        url: ["/monitoringevent"],
    },
    {
        responsibility: "monitoringEventWrite",
        method: ["post", "put", "delete"],
        url: ["/monitoringevent"],
    },
    {
        responsibility: "contractorRead",
        method: ["get"],
        url: ["/contractor", "/contractors-blocked"],
    },
    {
        responsibility: "processRead",
        method: ["get"],
        url: ["/processtype", "/approvergroup"],
    },
    {
        responsibility: "processWrite",
        method: ["post", "put", "delete"],
        url: ["/processtype", "/approvergroup"],
    },
    {
        responsibility: "scoringResultRead",
        method: ["get"],
        url: ["/scoringresult", "/scoringresult/properties"],
    },
    {
        responsibility: "scoringResultWrite",
        method: ["post"],
        url: ["/scoringresult"],
    },
    {
        responsibility: "blackListRead",
        method: [],
        url: [],
    },
    {
        responsibility: "approverRead",
        method: ["get"],
        url: ["/approver"],
    },
    {
        responsibility: "whiteListRead",
        method: [],
        url: [],
    },
    {
        responsibility: "contractorOnSubscriptionRead",
        method: ["get"],
        url: ["/contractoronsubscription"],
    },
    {
        responsibility: "contractorOnSubscriptionWrite",
        method: ["post", "put", "delete"],
        url: ["/contractoronsubscription"],
    },
    {
        responsibility: "userWrite",
        method: ["post", "delete", "put"],
        url: ["/user", "/role", "/permission"],
    },
    {
        responsibility: "userRead",
        method: ["get"],
        url: ["/user", "/role", "/permission", "/system/message-mdm"],
    },
    {
        responsibility: "scoringSettingsWrite",
        method: [],
        url: [],
    },
    {
        responsibility: "blackListWrite",
        method: [],
        url: [],
    },
    {
        responsibility: "whiteListWrite",
        method: [],
        url: [],
    },
    {
        responsibility: "expertRead",
        method: ["get"],
        url: ["/experts", "/expert-unscrupulous"],
    },
    {
        responsibility: "expertWrite",
        method: ["post", "put", "delete"],
        url: ["/experts"],
    },
];
