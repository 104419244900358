"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MENU_PROPS = exports.LOCATION_KEYS = exports.PERSISTENT_KEYS = exports.DEFAULT_PAGINATION = exports.PAGE_SIZES = void 0;
exports.PAGE_SIZES = [5, 10, 20, 30];
exports.DEFAULT_PAGINATION = {
    page: 0,
    pageSize: 10,
};
var PERSISTENT_KEYS;
(function (PERSISTENT_KEYS) {
    PERSISTENT_KEYS["contractorTypes"] = "contractorTypes";
    PERSISTENT_KEYS["filters"] = "filters";
    PERSISTENT_KEYS["pagination"] = "pagination";
    PERSISTENT_KEYS["page"] = "page";
    PERSISTENT_KEYS["pageSize"] = "pageSize";
    PERSISTENT_KEYS["tableFilters"] = "tableFilters";
})(PERSISTENT_KEYS = exports.PERSISTENT_KEYS || (exports.PERSISTENT_KEYS = {}));
var LOCATION_KEYS;
(function (LOCATION_KEYS) {
    LOCATION_KEYS["all"] = "all";
    LOCATION_KEYS["approverGroups"] = "approverGroups";
    LOCATION_KEYS["blockedContractors"] = "blockedContractors";
    LOCATION_KEYS["contractor"] = "contractor";
    LOCATION_KEYS["consumerTerrorists"] = "consumerTerrorists";
    LOCATION_KEYS["contractorOnSubscription"] = "contractorOnSubscription";
    LOCATION_KEYS["courtCases"] = "courtCases";
    LOCATION_KEYS["dishonestExpertOrganizations"] = "dishonestExpertOrganizations";
    LOCATION_KEYS["expertList"] = "expertList";
    LOCATION_KEYS["monitoringEvents"] = "monitoringEvents";
    LOCATION_KEYS["riskFactorList"] = "riskFactorList";
    LOCATION_KEYS["roleList"] = "roleList";
    LOCATION_KEYS["rolePermissions"] = "rolePermissions";
    LOCATION_KEYS["unscrupulousExpertList"] = "unscrupulousExpertList";
    LOCATION_KEYS["userList"] = "userList";
    LOCATION_KEYS["userRoles"] = "userRoles";
})(LOCATION_KEYS = exports.LOCATION_KEYS || (exports.LOCATION_KEYS = {}));
exports.MENU_PROPS = {
    anchorOrigin: {
        vertical: "bottom",
        horizontal: "left",
    },
    transformOrigin: {
        vertical: "bottom",
        horizontal: "left",
    },
};
