"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApproverGroupListPage = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var material_1 = require("@mui/material");
var react_1 = __importStar(require("react"));
var react_router_dom_1 = require("react-router-dom");
var constants_1 = require("../../../../../constants");
var helpers_1 = require("../../../../../helpers");
var useTableFilters_1 = require("../../../../../hooks/useTableFilters");
var routes_1 = require("../../../../../navigation/routes");
var store_1 = require("../../../../../store");
var processes_1 = require("../../../../processes");
var actions_1 = require("../../../../snackbar/store/actions");
var alert_dialog_1 = require("../../../../ud-ui/components/alert-dialog");
var filter_values_1 = require("../../../../ud-ui/components/filter-values");
var DataGrid_1 = require("../../../../ud-ui/components/table/DataGrid");
var permissions_1 = require("../../../../ud-ui/helpers/permissions");
var page_container_1 = require("../../../../ud-ui/pages/page-container");
var constants_2 = require("../../../domain/constants");
var getApproverGroupColumns_1 = require("../../../domain/helpers/getApproverGroupColumns");
var approversApi_1 = require("../../../store/api/approversApi");
var ApproverGroupListPage = function () {
    var history = (0, react_router_dom_1.useHistory)();
    var dispatch = (0, store_1.useAppDispatch)();
    var canCreatingGroup = (0, permissions_1.hasPermissionInStorage)("processWrite");
    var _a = (0, react_1.useState)(null), deletedApproverGroup = _a[0], setDeletedApproverGroup = _a[1];
    var _b = (0, useTableFilters_1.useTableFilters)(constants_1.LOCATION_KEYS.approverGroups, constants_2.approverGroupsTableFiltersValues), tableFilters = _b.tableFilters, pagination = _b.pagination, changeTableFilter = _b.changeTableFilter, deleteTableFilter = _b.deleteTableFilter, getTableFiltersParams = _b.getTableFiltersParams, setTableFilter = _b.setTableFilter, setPageParams = _b.setPageParams;
    var approverGroups = (0, approversApi_1.useFetchApproverGroupsQuery)((0, helpers_1.getQueryParams)(__assign(__assign({}, getTableFiltersParams()), (0, helpers_1.getPaginationParams)(pagination))));
    var _c = (0, approversApi_1.useDeleteApproverGroupMutation)(), deleteApproverGroup = _c[0], approverGroupResult = _c[1];
    (0, react_1.useEffect)(function () {
        if (approverGroupResult.isSuccess) {
            dispatch(processes_1.processTypesApi.util.invalidateTags(["ProcessTypes"]));
            dispatch((0, actions_1.showSnackbar)({
                snackbarMessage: constants_1.alertMessages.deleteApproverGroupSuccess,
                snackbarType: "info",
            }));
        }
    }, [approverGroupResult.isSuccess]);
    var handleCreateClick = (0, react_1.useCallback)(function () {
        history.push(routes_1.routes.approversGroupCreate);
    }, []);
    var handleEditClick = (0, react_1.useCallback)(function (approverGroupId) {
        history.push((0, react_router_dom_1.generatePath)(routes_1.routes.approversGroupEdit, { approverGroupId: approverGroupId }), { pagination: pagination, tableFilters: tableFilters });
    }, [pagination, tableFilters]);
    var handleDeleteClick = (0, react_1.useCallback)(function (approversGroup) {
        setDeletedApproverGroup(approversGroup);
    }, []);
    var handleDeleteCancel = (0, react_1.useCallback)(function () {
        setDeletedApproverGroup(null);
    }, []);
    var handleDeleteSubmit = (0, react_1.useCallback)(function () {
        if (deletedApproverGroup) {
            deleteApproverGroup(deletedApproverGroup.id);
            setDeletedApproverGroup(null);
        }
    }, [deletedApproverGroup]);
    var dataGridProps = react_1.default.useMemo(function () {
        var _a, _b, _c, _d;
        return ({
            columns: (0, getApproverGroupColumns_1.getApproverGroupColumns)({
                onSubmit: setTableFilter,
                onDelete: handleDeleteClick,
                onEdit: handleEditClick,
            }),
            rows: (_b = (_a = approverGroups.data) === null || _a === void 0 ? void 0 : _a.rows) !== null && _b !== void 0 ? _b : [],
            rowCount: (_d = (_c = approverGroups.data) === null || _c === void 0 ? void 0 : _c.total) !== null && _d !== void 0 ? _d : 0,
        });
    }, [approverGroups.data]);
    return ((0, jsx_runtime_1.jsxs)(page_container_1.PageContainer, __assign({ subTitle: "\u0421\u043F\u0438\u0441\u043A\u0438 \u0441\u043E\u0433\u043B\u0430\u0441\u0443\u044E\u0449\u0438\u0445", leftElement: (0, jsx_runtime_1.jsx)(material_1.Button, __assign({ "data-id": "addApproverButton", disabled: !canCreatingGroup, size: "small", variant: "outlined", onClick: handleCreateClick }, { children: "\u0414\u043E\u0431\u0430\u0432\u0438\u0442\u044C" })) }, { children: [(0, jsx_runtime_1.jsx)(filter_values_1.FilterValues, { values: tableFilters, onDelete: deleteTableFilter, onChange: changeTableFilter }), (0, jsx_runtime_1.jsx)(DataGrid_1.UDDataGrid, __assign({}, dataGridProps, pagination, { isLoading: approverGroups.isFetching, onPageModeChange: setPageParams })), deletedApproverGroup && ((0, jsx_runtime_1.jsx)(alert_dialog_1.AlertDialog, { isOpen: true, description: "\u0412\u044B \u0443\u0432\u0435\u0440\u0435\u043D\u044B, \u0447\u0442\u043E \u0445\u043E\u0442\u0438\u0442\u0435 \u0443\u0434\u0430\u043B\u0438\u0442\u044C \u0441\u043F\u0438\u0441\u043E\u043A \"".concat(deletedApproverGroup.name, "\"?"), onCancel: handleDeleteCancel, onSubmit: handleDeleteSubmit }))] })));
};
exports.ApproverGroupListPage = ApproverGroupListPage;
