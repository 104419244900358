"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.blockedContractorsFilters = void 0;
exports.blockedContractorsFilters = {
    name: {
        id: "name",
        label: "Контрагент",
        value: "",
    },
    inn: {
        id: "inn",
        label: "ИНН",
        value: "",
    },
    kpp: {
        id: "kpp",
        label: "КПП",
        value: "",
    },
    ogrn: {
        id: "ogrn",
        label: "ОГРН",
        value: "",
    },
};
