"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RoleForm = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var yup_1 = require("@hookform/resolvers/yup/dist/yup");
var material_1 = require("@mui/material");
var react_1 = require("react");
var react_hook_form_1 = require("react-hook-form");
var yup = __importStar(require("yup"));
var constants_1 = require("../../../../../constants");
var S = __importStar(require("../../../../../styles"));
var schema = yup.object({
    name: yup.string().max(256, constants_1.TEXTS.getMaxLength(256)).required(constants_1.TEXTS.requiredField),
    description: yup.string().max(256, constants_1.TEXTS.getMaxLength(256)).required(constants_1.TEXTS.requiredField),
});
exports.RoleForm = (0, react_1.memo)(function (_a) {
    var _b, _c, _d, _e;
    var isDisabled = _a.isDisabled, role = _a.role, onSubmit = _a.onSubmit;
    var _f = (0, react_hook_form_1.useForm)({
        defaultValues: role,
        mode: "onChange",
        resolver: (0, yup_1.yupResolver)(schema),
    }), register = _f.register, handleSubmit = _f.handleSubmit, _g = _f.formState, errors = _g.errors, isDirty = _g.isDirty, isValid = _g.isValid;
    return ((0, jsx_runtime_1.jsxs)("form", __assign({ onSubmit: handleSubmit(onSubmit) }, { children: [(0, jsx_runtime_1.jsxs)(S.FieldLayout, __assign({ "data-id": "nameField" }, { children: [(0, jsx_runtime_1.jsx)(S.Label, __assign({ "data-id": "nameLabel" }, { children: "\u041D\u0430\u0438\u043C\u0435\u043D\u043E\u0432\u0430\u043D\u0438\u0435:" })), (0, jsx_runtime_1.jsx)(S.Field, { children: (0, jsx_runtime_1.jsx)(material_1.TextField, __assign({}, register("name", { required: true }), { fullWidth: true, error: !!((_b = errors === null || errors === void 0 ? void 0 : errors.name) === null || _b === void 0 ? void 0 : _b.message), helperText: (_c = errors === null || errors === void 0 ? void 0 : errors.name) === null || _c === void 0 ? void 0 : _c.message, name: "name", variant: "outlined" })) })] })), (0, jsx_runtime_1.jsxs)(S.FieldLayout, __assign({ "data-id": "descriptionField" }, { children: [(0, jsx_runtime_1.jsx)(S.Label, __assign({ "data-id": "descriptionLabel" }, { children: "\u041B\u043E\u0433\u0438\u043D:" })), (0, jsx_runtime_1.jsx)(S.Field, { children: (0, jsx_runtime_1.jsx)(material_1.TextField, __assign({}, register("description", { required: true }), { fullWidth: true, error: !!((_d = errors === null || errors === void 0 ? void 0 : errors.description) === null || _d === void 0 ? void 0 : _d.message), helperText: (_e = errors === null || errors === void 0 ? void 0 : errors.description) === null || _e === void 0 ? void 0 : _e.message, name: "description", variant: "outlined" })) })] })), (0, jsx_runtime_1.jsx)(S.ActionsLayout, { children: (0, jsx_runtime_1.jsx)(material_1.Button, __assign({ color: "primary", disabled: !isDirty || !isValid || isDisabled, type: "submit", variant: "contained" }, { children: "\u0421\u043E\u0445\u0440\u0430\u043D\u0438\u0442\u044C" })) })] })));
});
exports.RoleForm.displayName = "RoleFormPage";
