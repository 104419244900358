"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getSavedPagination = exports.getSavedContractorTypes = exports.getSavedFilters = exports.getSavedTableFilters = exports.savePersistentData = exports.saveSessionData = exports.getStorageKey = exports.getFilterValue = void 0;
var constants_1 = require("../constants");
function getFilterValue(newValues, oldValues) {
    return typeof newValues === typeof oldValues ? newValues : oldValues;
}
exports.getFilterValue = getFilterValue;
function getStorageKey(locationKey, key) {
    var _a;
    return (_a = [locationKey, key].filter(Boolean).join(".")) !== null && _a !== void 0 ? _a : "scoring";
}
exports.getStorageKey = getStorageKey;
function getFormattedData(value) {
    var data = typeof value === "string" ? value : "";
    if (value instanceof Object) {
        data = JSON.stringify(value);
    }
    if (typeof value === "number") {
        data = value.toString();
    }
    return data;
}
function saveSessionData(value, locationKey, key) {
    var data = getFormattedData(value);
    var storageKey = getStorageKey(locationKey, key);
    if (data) {
        sessionStorage.setItem(storageKey, data);
    }
    else {
        sessionStorage.removeItem(storageKey);
    }
}
exports.saveSessionData = saveSessionData;
function savePersistentData(value, locationKey, key) {
    var data = getFormattedData(value);
    var storageKey = getStorageKey(locationKey, key);
    if (data) {
        localStorage.setItem(storageKey, data);
    }
    else {
        localStorage.removeItem(storageKey);
    }
}
exports.savePersistentData = savePersistentData;
function getSavedTableFilters(key, defaultTableFilters) {
    try {
        var value = sessionStorage.getItem(getStorageKey(key, constants_1.PERSISTENT_KEYS.tableFilters));
        if (!defaultTableFilters || !value) {
            return defaultTableFilters;
        }
        var parsedData_1 = JSON.parse(value);
        var tableFilters = Object.keys(defaultTableFilters).reduce(function (a, c) {
            var _a;
            var id = c;
            return __assign(__assign({}, a), (_a = {}, _a[id] = __assign(__assign({}, defaultTableFilters[id]), { value: getFilterValue(parsedData_1[id].value, defaultTableFilters[id].value) }), _a));
        }, defaultTableFilters);
        return tableFilters;
    }
    catch (_a) {
        return defaultTableFilters;
    }
}
exports.getSavedTableFilters = getSavedTableFilters;
function getSavedFilters(key, defaultFilters) {
    try {
        var value = sessionStorage.getItem(getStorageKey(key, constants_1.PERSISTENT_KEYS.filters));
        if (!defaultFilters || !value) {
            return defaultFilters !== null && defaultFilters !== void 0 ? defaultFilters : {};
        }
        var parsedData_2 = JSON.parse(value);
        return Object.keys(defaultFilters).reduce(function (a, c) {
            var _a;
            var id = c;
            return __assign(__assign({}, a), (_a = {}, _a[id] = getFilterValue(parsedData_2[id], defaultFilters[id]), _a));
        }, defaultFilters);
    }
    catch (_a) {
        return defaultFilters !== null && defaultFilters !== void 0 ? defaultFilters : {};
    }
}
exports.getSavedFilters = getSavedFilters;
function getSavedContractorTypes(key) {
    try {
        var value = sessionStorage.getItem(getStorageKey(key, constants_1.PERSISTENT_KEYS.contractorTypes));
        if (!value) {
            return [];
        }
        var parsedData = JSON.parse(value);
        return Array.isArray(parsedData) ? parsedData : [];
    }
    catch (_a) {
        return [];
    }
}
exports.getSavedContractorTypes = getSavedContractorTypes;
function getSavedPagination(key) {
    try {
        var page = Number(sessionStorage.getItem("".concat(key, ".page")));
        var pageSize = Number(localStorage.getItem("".concat(constants_1.LOCATION_KEYS.all, ".pageSize")));
        return {
            page: page > 0 ? page : constants_1.DEFAULT_PAGINATION.page,
            pageSize: pageSize > 0 ? pageSize : constants_1.DEFAULT_PAGINATION.pageSize,
        };
    }
    catch (_a) {
        return constants_1.DEFAULT_PAGINATION;
    }
}
exports.getSavedPagination = getSavedPagination;
