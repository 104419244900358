"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.unscrupulousExpertsFilters = void 0;
exports.unscrupulousExpertsFilters = {
    name: {
        id: "name",
        label: "ФИО",
        value: "",
    },
    period: {
        id: "period",
        label: "",
        value: "",
    },
    riskIndicatorName: {
        id: "riskIndicatorName",
        label: "Риск-Индикатор",
        value: "",
    },
};
