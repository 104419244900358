"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.roleRoutes = exports.rolesTableFiltersValues = void 0;
var edit_role_page_1 = require("../../ui/pages/edit-role-page");
var role_permissions_page_1 = require("../../ui/pages/role-permissions-page");
exports.rolesTableFiltersValues = {
    description: {
        id: "description",
        label: "Роль",
        value: "",
    },
};
exports.roleRoutes = [
    {
        Component: edit_role_page_1.EditRolePage,
        label: "Основные параметры",
        permission: "userWrite",
        tabId: "profile",
    },
    {
        Component: role_permissions_page_1.RolePermissionsPage,
        label: "Разрешения",
        permission: "userWrite",
        tabId: "permissions",
    },
];
