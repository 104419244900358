"use strict";
/* TODO: fix */
/* eslint-disable @typescript-eslint/no-explicit-any */
Object.defineProperty(exports, "__esModule", { value: true });
exports.createEntitiesSlice = void 0;
var toolkit_1 = require("@reduxjs/toolkit");
function createEntitiesSlice(actions, name) {
    var initialState = {
        isLoading: true,
        error: "",
        entities: [],
        total: 0,
        tasks: [],
        isOverflowed: true,
        currentEntity: [],
    };
    return (0, toolkit_1.createSlice)({
        name: name,
        initialState: initialState,
        reducers: {},
        extraReducers: function (builder) {
            builder.addCase(actions.clearError, function (state) {
                state.error = "";
            });
        },
    });
}
exports.createEntitiesSlice = createEntitiesSlice;
