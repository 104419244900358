"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var toolkit_1 = require("@reduxjs/toolkit");
var actions_1 = require("./actions");
var initialState = {
    snackbarMessage: "",
    snackbarType: undefined,
    snackbarOpen: false,
};
var setValuesToStore = function (state, payload, snackbarOpen) {
    if (payload) {
        var snackbarMessage = payload.snackbarMessage, snackbarType = payload.snackbarType;
        state.snackbarType = snackbarType;
        state.snackbarMessage = snackbarMessage;
    }
    state.snackbarOpen = snackbarOpen;
};
var slice = (0, toolkit_1.createSlice)({
    name: "snackbar",
    initialState: initialState,
    reducers: {},
    extraReducers: function (builder) {
        builder.addCase(actions_1.showSnackbar, function (state, _a) {
            var payload = _a.payload;
            return setValuesToStore(state, payload, true);
        });
        builder.addCase(actions_1.dismissSnackBar, function (state, _a) {
            var payload = _a.payload;
            return setValuesToStore(state, payload, false);
        });
    },
});
exports.default = slice.reducer;
