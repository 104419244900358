"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
var toolkit_1 = require("@reduxjs/toolkit");
var actions_1 = require("./actions");
var initialState = {
    filesFromDb: [],
    syncFiles: [],
    files: [],
};
var slice = (0, toolkit_1.createSlice)({
    name: "files",
    initialState: initialState,
    reducers: {},
    extraReducers: function (builder) {
        builder.addCase(actions_1.uploadFilesToDataBase.fulfilled, function (state, _a) {
            var payload = _a.payload;
            state.filesFromDb = payload.data.payload;
        });
        builder.addCase(actions_1.syncFileWithEvent.fulfilled, function (state, _a) {
            var payload = _a.payload;
            state.syncFiles = __spreadArray(__spreadArray([], state.syncFiles, true), payload.data.payload, true);
        });
        builder.addCase(actions_1.getAttachments.fulfilled, function (state, _a) {
            var payload = _a.payload;
            state.files = payload.data.payload;
        });
        builder.addCase(actions_1.getAttachments.pending, function (state) {
            state.files = [];
        });
    },
});
exports.default = slice.reducer;
