"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.columns = void 0;
exports.columns = [
    { field: "id", headerName: "ID" },
    { field: "type", headerName: "Тип" },
    { field: "status", headerName: "Статус" },
    { field: "errorText", headerName: "Идентификатор" },
    { field: "countAttempts", headerName: "Попыток" },
];
