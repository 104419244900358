"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ConsumerPage = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var react_router_dom_1 = require("react-router-dom");
var go_back_container_1 = require("../../../../ud-ui/components/go-back-container");
var profile_info_1 = require("../../../../ud-ui/components/profile-info");
var DataGrid_1 = require("../../../../ud-ui/components/table/DataGrid");
var page_container_1 = require("../../../../ud-ui/pages/page-container");
var get_profile_info_data_1 = require("../../../domain/helpers/get-profile-info-data");
var get_consumer_riskIndicators_columns_1 = require("../../../domain/helpers/get-consumer-riskIndicators-columns");
var consumersApi_1 = require("../../../store/consumersApi");
var ConsumerPage = function (props) {
    var _a, _b, _c;
    var consumerId = (0, react_router_dom_1.useParams)().id;
    var consumer = (0, consumersApi_1.useFetchConsumerQuery)(consumerId);
    var profileInfoData = (0, react_1.useMemo)(function () { return (0, get_profile_info_data_1.getProfileInfoData)(consumer.data); }, [consumer.data]);
    if (!consumer.isSuccess) {
        return null;
    }
    return ((0, jsx_runtime_1.jsxs)(page_container_1.PageContainer, __assign({ subTitle: "\u041A\u0430\u0440\u0442\u043E\u0447\u043A\u0430 \u043F\u043E\u0442\u0440\u0435\u0431\u0438\u0442\u0435\u043B\u044F", leftElement: (0, jsx_runtime_1.jsx)(go_back_container_1.GoBackContainer, { goBackPath: props.goBackPath }) }, { children: [(0, jsx_runtime_1.jsx)(profile_info_1.ProfileInfo, __assign({}, profileInfoData, { name: consumer.data.name })), (0, jsx_runtime_1.jsx)(DataGrid_1.UDDataGrid, { hideFooter: true, columns: (0, get_consumer_riskIndicators_columns_1.getConsumerRiskIndicatorsColumns)(), rowCount: (_b = (_a = consumer.data.riskIndicators) === null || _a === void 0 ? void 0 : _a.length) !== null && _b !== void 0 ? _b : 0, rows: (_c = consumer.data.riskIndicators) !== null && _c !== void 0 ? _c : [] })] })));
};
exports.ConsumerPage = ConsumerPage;
