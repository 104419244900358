"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getConsumerTerroristsColumns = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var constants_1 = require("../../../../constants");
var helpers_1 = require("../../../../helpers");
var date_filter_1 = require("../../../ud-ui/components/date-filter");
var risk_indicator_name_1 = require("../../../ud-ui/components/risk-indicator-name");
var text_filter_1 = require("../../../ud-ui/components/text-filter");
var getConsumerTerroristsColumns = function (props) {
    var birthDate = props.birthDate, onSubmit = props.onSubmit;
    return [
        {
            field: "name",
            filterable: false,
            flex: 300,
            minWidth: 220,
            sortable: false,
            renderHeader: function () { return (0, jsx_runtime_1.jsx)(text_filter_1.TextFilter, { name: "\u0424\u0418\u041E", onSubmit: onSubmit("name"), id: "name" }); },
        },
        {
            field: "realEstateName",
            filterable: false,
            flex: 300,
            minWidth: 250,
            sortable: false,
            renderHeader: function () { return ((0, jsx_runtime_1.jsx)(text_filter_1.TextFilter, { name: "\u041E\u0431\u044A\u0435\u043A\u0442 \u043D\u0435\u0434\u0432\u0438\u0436\u0438\u043C\u043E\u0441\u0442\u0438", onSubmit: onSubmit("realEstateName"), id: "realEstateName" })); },
        },
        {
            field: "birthDate",
            filterable: false,
            flex: 200,
            minWidth: 190,
            sortable: false,
            renderHeader: function () { return ((0, jsx_runtime_1.jsx)(date_filter_1.DateFilter, { fieldValue: birthDate, id: "birthDate", name: "\u0414\u0430\u0442\u0430 \u0440\u043E\u0436\u0434\u0435\u043D\u0438\u044F", onSubmit: onSubmit("birthDate") })); },
            valueFormatter: function (params) { return (0, helpers_1.getFormattedDate)(params.value, constants_1.DateFormats.Date); },
        },
        {
            field: "riskIndicatorName",
            filterable: false,
            flex: 300,
            minWidth: 240,
            sortable: false,
            renderHeader: function () { return (0, jsx_runtime_1.jsx)(text_filter_1.TextFilter, { name: "\u041F\u0440\u0438\u0437\u043D\u0430\u043A", onSubmit: onSubmit("riskIndicatorName"), id: "riskIndicatorName" }); },
            renderCell: function (params) { return ((0, jsx_runtime_1.jsx)(risk_indicator_name_1.RiskIndicatorName, { name: params.value, scoringResultId: params.row.scoringResultId, scoringResultType: "contractor" })); },
        },
        {
            field: "period",
            filterable: false,
            flex: 200,
            minWidth: 140,
            headerName: "Дата выявления",
            sortingOrder: ["desc", "asc"],
            type: "date",
            valueFormatter: function (params) {
                return (0, helpers_1.getFormattedDate)(params.value, constants_1.DateFormats.ShortTextDateTime);
            },
        },
    ];
};
exports.getConsumerTerroristsColumns = getConsumerTerroristsColumns;
