"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.contractorFiltersValues = exports.contractorRoutes = exports.contractorTypeOptions = exports.ContractorTypes = void 0;
var contractor_court_cases_page_1 = require("../../ui/pages/contractor-court-cases-page");
var contractor_experts_page_1 = require("../../ui/pages/contractor-experts-page");
var contractor_risk_factors_page_1 = require("../../ui/pages/contractor-risk-factors-page");
var contractor_risk_indicators_page_1 = require("../../ui/pages/contractor-risk-indicators-page");
var ContractorTypes;
(function (ContractorTypes) {
    ContractorTypes["Contractor"] = "contractor";
    ContractorTypes["Expert"] = "expert";
    ContractorTypes["Organization"] = "organization";
    ContractorTypes["Other"] = "other";
    ContractorTypes["None"] = "none";
})(ContractorTypes = exports.ContractorTypes || (exports.ContractorTypes = {}));
exports.contractorTypeOptions = [
    { label: "Подрядчик", value: ContractorTypes.Contractor },
    { label: "Эксперт", value: ContractorTypes.Expert },
    { label: "Организация", value: ContractorTypes.Organization },
    { label: "Прочее", value: ContractorTypes.Other },
    { label: "Не указано", value: ContractorTypes.None },
];
exports.contractorRoutes = [
    {
        label: "Риск-факторы",
        tabId: "risk-factors",
        Component: contractor_risk_factors_page_1.ContractorRiskFactorsPage,
    },
    {
        label: "Эксперты",
        tabId: "experts",
        Component: contractor_experts_page_1.ContractorExpertsPage,
    },
    {
        label: "Признаки недобросовестности экспертной организации",
        tabId: "risk-indicators",
        Component: contractor_risk_indicators_page_1.ContractorRiskIndicatorsPage,
    },
    {
        label: "Судебные дела",
        tabId: "court-cases",
        Component: contractor_court_cases_page_1.CourtCasesPage,
    },
];
exports.contractorFiltersValues = {
    riskFactorName: {
        id: "riskFactorName",
        label: "Риск-факторы",
        value: [],
    },
};
