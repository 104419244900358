"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RoleListPage = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var material_1 = require("@mui/material");
var react_1 = __importStar(require("react"));
var react_router_1 = require("react-router");
var react_router_dom_1 = require("react-router-dom");
var constants_1 = require("../../../../../constants");
var helpers_1 = require("../../../../../helpers");
var useSnackbar_1 = require("../../../../../hooks/useSnackbar");
var useTableFilters_1 = require("../../../../../hooks/useTableFilters");
var routes_1 = require("../../../../../navigation/routes");
var alert_dialog_1 = require("../../../../ud-ui/components/alert-dialog");
var filter_values_1 = require("../../../../ud-ui/components/filter-values");
var DataGrid_1 = require("../../../../ud-ui/components/table/DataGrid");
var permissions_1 = require("../../../../ud-ui/helpers/permissions");
var page_container_1 = require("../../../../ud-ui/pages/page-container");
var constants_2 = require("../../../domain/constants");
var getRoleColumns_1 = require("../../../domain/helpers/getRoleColumns");
var rolesApi_1 = require("../../../store/api/rolesApi");
var RoleListPage = function () {
    var history = (0, react_router_1.useHistory)();
    var _a = (0, react_1.useState)(null), deletedRole = _a[0], setDeletedRole = _a[1];
    var _b = (0, useTableFilters_1.useTableFilters)(constants_1.LOCATION_KEYS.roleList, constants_2.rolesTableFiltersValues), tableFilters = _b.tableFilters, pagination = _b.pagination, changeTableFilter = _b.changeTableFilter, deleteTableFilter = _b.deleteTableFilter, getTableFiltersParams = _b.getTableFiltersParams, setTableFilter = _b.setTableFilter, setPageParams = _b.setPageParams;
    var canCreating = (0, permissions_1.hasPermissionInStorage)("userWrite");
    var roles = (0, rolesApi_1.useFetchRolesQuery)((0, helpers_1.getQueryParams)(__assign(__assign({}, getTableFiltersParams()), (0, helpers_1.getPaginationParams)(pagination))));
    var _c = (0, rolesApi_1.useDeleteRoleMutation)(), deleteRole = _c[0], deleteRoleResults = _c[1];
    (0, useSnackbar_1.useSuccessAlert)(deleteRoleResults, constants_1.alertMessages.deleteRoleSuccess);
    var handleRoleCreateClick = (0, react_1.useCallback)(function () {
        history.push(routes_1.routes.createRole, { pagination: pagination, tableFilters: tableFilters });
    }, []);
    var handleRoleEditClick = (0, react_1.useCallback)(function (id) {
        history.push((0, react_router_dom_1.generatePath)(routes_1.routes.editRoleProfile, { id: id }), { pagination: pagination, tableFilters: tableFilters });
    }, [pagination, tableFilters]);
    var handleRoleDeleteClick = (0, react_1.useCallback)(function (role) {
        setDeletedRole(role);
    }, []);
    var handleRoleDeleteCancel = (0, react_1.useCallback)(function () {
        setDeletedRole(null);
    }, []);
    var handleRoleDeleteSubmit = (0, react_1.useCallback)(function () {
        if (deletedRole) {
            deleteRole(deletedRole.id);
            setDeletedRole(null);
        }
    }, [deletedRole]);
    var dataGridProps = react_1.default.useMemo(function () {
        var _a, _b, _c, _d;
        return ({
            columns: (0, getRoleColumns_1.getRoleColumns)({
                onSubmit: setTableFilter,
                onDelete: handleRoleDeleteClick,
                onEdit: handleRoleEditClick,
            }),
            rows: (_b = (_a = roles.data) === null || _a === void 0 ? void 0 : _a.rows) !== null && _b !== void 0 ? _b : [],
            rowCount: (_d = (_c = roles.data) === null || _c === void 0 ? void 0 : _c.total) !== null && _d !== void 0 ? _d : 0,
        });
    }, [roles.data]);
    return ((0, jsx_runtime_1.jsxs)(page_container_1.PageContainer, __assign({ subTitle: "\u0420\u043E\u043B\u0438 \u0438 \u043F\u0440\u0430\u0432\u0430", leftElement: (0, jsx_runtime_1.jsx)(material_1.Button, __assign({ "data-id": "addRoleButton", disabled: !canCreating, size: "small", variant: "outlined", onClick: handleRoleCreateClick }, { children: "\u0414\u043E\u0431\u0430\u0432\u0438\u0442\u044C" })) }, { children: [(0, jsx_runtime_1.jsx)(filter_values_1.FilterValues, { values: tableFilters, onDelete: deleteTableFilter, onChange: changeTableFilter }), (0, jsx_runtime_1.jsx)(DataGrid_1.UDDataGrid, __assign({}, dataGridProps, pagination, { isLoading: roles.isFetching, onPageModeChange: setPageParams })), deletedRole && ((0, jsx_runtime_1.jsx)(alert_dialog_1.AlertDialog, { isOpen: true, description: "\u0412\u044B \u0443\u0432\u0435\u0440\u0435\u043D\u044B, \u0447\u0442\u043E \u0445\u043E\u0442\u0438\u0442\u0435 \u0443\u0434\u0430\u043B\u0438\u0442\u044C \u0440\u043E\u043B\u044C \"".concat(deletedRole.description, "\"? \u042D\u0442\u043E \u043F\u0440\u0438\u0432\u0435\u0434\u0435\u0442 \u043A \u0443\u0434\u0430\u043B\u0435\u043D\u0438\u044E \u043D\u0430\u0437\u043D\u0430\u0447\u0435\u043D\u043D\u043E\u0439 \u0440\u043E\u043B\u0438 \u0443 \u0432\u0441\u0435\u0445 \u043F\u043E\u043B\u044C\u0437\u043E\u0432\u0430\u0442\u0435\u043B\u0435\u0439"), onCancel: handleRoleDeleteCancel, onSubmit: handleRoleDeleteSubmit }))] })));
};
exports.RoleListPage = RoleListPage;
