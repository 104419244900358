"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.expertsTableFiltersValues = void 0;
exports.expertsTableFiltersValues = {
    name: {
        id: "name",
        label: "ФИО",
        value: "",
    },
    expertOrganizationName: {
        id: "expertOrganizationName",
        label: "Экспертная организация",
        value: "",
    },
};
