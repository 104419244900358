"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getRiskIndicatorsColumns = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var helpers_1 = require("../../../../helpers");
var risk_indicator_name_1 = require("../../../ud-ui/components/risk-indicator-name");
var getRiskIndicatorsColumns = function () { return [
    {
        field: "name",
        flex: 600,
        filterable: false,
        headerName: "Риск-индикатор",
        renderCell: function (params) { return ((0, jsx_runtime_1.jsx)(risk_indicator_name_1.RiskIndicatorName, { name: params.value, scoringResultId: params.row.scoringResultId, scoringResultType: "expert" })); },
    },
    {
        field: "date",
        filterable: false,
        headerName: "Дата блокировки",
        minWidth: 220,
        valueFormatter: function (params) { return (0, helpers_1.getFormattedDate)(params.value); },
    },
]; };
exports.getRiskIndicatorsColumns = getRiskIndicatorsColumns;
