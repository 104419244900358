"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.permissionsEntitiesStore = void 0;
var create_store_1 = require("../../../store-factories/entities/create-store");
var store = (0, create_store_1.createEntitiesStore)("permission", {
    stateSelector: function (state) { return state.permissions.entities; },
});
var reducer = store.reducer;
exports.permissionsEntitiesStore = store;
exports.default = reducer;
