"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UNCONFIRMED_DEFECTS_SETTINGS = void 0;
exports.UNCONFIRMED_DEFECTS_SETTINGS = [
    {
        id: "forensicExaminationReportNumber",
        isTechnical: false,
        title: "Экспертиза №",
    },
    {
        id: "forensicExaminationReportId",
        isTechnical: true,
        title: "СИД Экспертизы",
    },
    {
        id: "confirmedIssuesCount",
        isTechnical: false,
        title: "Количество подтвержденных замечаний",
    },
    {
        id: "unconfirmedIssuesCount",
        isTechnical: false,
        title: "Количество неподтвержденных замечаний",
    },
];
