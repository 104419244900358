"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useUpdateRoleMutation = exports.useFetchRoleQuery = exports.useFetchUserRolePermissionsQuery = exports.useFetchRolePermissionsQuery = exports.useFetchRolesQuery = exports.useDeleteRolePermissionMutation = exports.useCreateRolePermissionMutation = exports.useDeleteRoleMutation = exports.useCreateRoleMutation = exports.rolesApi = void 0;
var react_1 = require("@reduxjs/toolkit/query/react");
var baseQuery_1 = require("../../../../api/baseQuery");
exports.rolesApi = (0, react_1.createApi)({
    reducerPath: "roleList",
    baseQuery: baseQuery_1.baseQuery,
    tagTypes: ["Role", "Roles", "RolePermissions", "UserRolePermissions"],
    endpoints: function (builder) { return ({
        createRole: builder.mutation({
            query: function (body) { return ({
                url: "/api/v1/role",
                method: "POST",
                body: body,
            }); },
            invalidatesTags: ["Roles"],
            transformResponse: function (result) { return result.payload; },
        }),
        deleteRole: builder.mutation({
            query: function (id) { return ({
                url: "/api/v1/role/".concat(id),
                method: "DELETE",
            }); },
            invalidatesTags: ["Roles"],
        }),
        fetchRole: builder.query({
            query: function (id) { return ({
                url: "/api/v1/role/".concat(id),
            }); },
            providesTags: ["Role"],
            transformResponse: function (result) { return result.payload; },
        }),
        fetchRoles: builder.query({
            query: function (params) { return ({
                url: "/api/v1/role".concat(params),
            }); },
            providesTags: ["Roles"],
            transformResponse: function (result) { return ({
                rows: result.payload,
                total: result.meta.total,
            }); },
        }),
        updateRole: builder.mutation({
            query: function (_a) {
                var body = _a.body, id = _a.id;
                return ({
                    url: "/api/v1/role/".concat(id),
                    method: "PUT",
                    body: body,
                });
            },
            invalidatesTags: ["Role", "Roles"],
        }),
        createRolePermission: builder.mutation({
            query: function (_a) {
                var id = _a.id, permissionsIds = _a.permissionsIds;
                return ({
                    url: "/api/v1/role/".concat(id, "/permissions"),
                    body: {
                        permissionsIds: permissionsIds,
                    },
                    method: "POST",
                });
            },
            invalidatesTags: ["UserRolePermissions"],
        }),
        deleteRolePermission: builder.mutation({
            query: function (_a) {
                var id = _a.id, permissionsIds = _a.permissionsIds;
                return ({
                    url: "/api/v1/role/".concat(id, "/permissions?permissionsIds=").concat(permissionsIds),
                    body: {
                        permissionsIds: permissionsIds,
                    },
                    method: "DELETE",
                });
            },
            invalidatesTags: ["UserRolePermissions"],
        }),
        fetchRolePermissions: builder.query({
            query: function () { return ({
                url: "/api/v1/permission",
            }); },
            providesTags: ["RolePermissions"],
            transformResponse: function (result) { return result.payload; },
        }),
        fetchUserRolePermissions: builder.query({
            query: function (id) { return ({
                url: "/api/v1/role/".concat(id, "/permissions"),
            }); },
            providesTags: ["UserRolePermissions"],
            transformResponse: function (result) { return result.payload; },
        }),
    }); },
});
exports.useCreateRoleMutation = exports.rolesApi.useCreateRoleMutation, exports.useDeleteRoleMutation = exports.rolesApi.useDeleteRoleMutation, exports.useCreateRolePermissionMutation = exports.rolesApi.useCreateRolePermissionMutation, exports.useDeleteRolePermissionMutation = exports.rolesApi.useDeleteRolePermissionMutation, exports.useFetchRolesQuery = exports.rolesApi.useFetchRolesQuery, exports.useFetchRolePermissionsQuery = exports.rolesApi.useFetchRolePermissionsQuery, exports.useFetchUserRolePermissionsQuery = exports.rolesApi.useFetchUserRolePermissionsQuery, exports.useFetchRoleQuery = exports.rolesApi.useFetchRoleQuery, exports.useUpdateRoleMutation = exports.rolesApi.useUpdateRoleMutation;
