"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NEGATIVE_EXPERT_SETTINGS = void 0;
exports.NEGATIVE_EXPERT_SETTINGS = [
    {
        id: "expertName",
        isTechnical: false,
        title: "ФИО эксперта",
    },
    {
        id: "expertId",
        isTechnical: true,
        title: "СИД Эксперта",
    },
];
