"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useUpdateRiskFactorMutation = exports.useFetchRiskFactorValueTypesQuery = exports.useFetchRiskFactorsQuery = exports.useFetchRiskFactorQuery = exports.useDeleteRiskFactorMutation = exports.useCreateRiskFactorMutation = exports.riskFactorApi = void 0;
var react_1 = require("@reduxjs/toolkit/query/react");
var baseQuery_1 = require("../../../../api/baseQuery");
exports.riskFactorApi = (0, react_1.createApi)({
    reducerPath: "riskFactors",
    baseQuery: baseQuery_1.baseQuery,
    tagTypes: ["RiskFactor", "RiskFactors", "RiskFactorValueTypes"],
    endpoints: function (builder) { return ({
        createRiskFactor: builder.mutation({
            query: function (body) { return ({
                url: "/api/v1/riskfactor",
                method: "POST",
                body: body,
            }); },
            invalidatesTags: ["RiskFactors"],
            transformResponse: function (result) { return result.payload; },
        }),
        deleteRiskFactor: builder.mutation({
            query: function (id) { return ({
                url: "/api/v1/riskfactor/".concat(id),
                method: "DELETE",
            }); },
            invalidatesTags: ["RiskFactors"],
        }),
        fetchRiskFactor: builder.query({
            query: function (id) { return ({
                url: "/api/v1/riskfactor/".concat(id),
            }); },
            providesTags: ["RiskFactor"],
            transformResponse: function (result) { return result.payload; },
        }),
        fetchRiskFactorValueTypes: builder.query({
            query: function () { return ({
                url: "/api/v1/riskfactorvaluetype/search",
            }); },
            providesTags: ["RiskFactorValueTypes"],
            transformResponse: function (result) { return result.payload; },
        }),
        fetchRiskFactors: builder.query({
            query: function (params) { return ({
                url: "/api/v1/riskfactor".concat(params),
            }); },
            providesTags: ["RiskFactors"],
            transformResponse: function (result) { return ({
                rows: result.payload,
                total: result.meta.total,
            }); },
        }),
        updateRiskFactor: builder.mutation({
            query: function (_a) {
                var body = _a.body, id = _a.id;
                return ({
                    url: "/api/v1/riskfactor/".concat(id),
                    method: "PUT",
                    body: body,
                });
            },
            invalidatesTags: ["RiskFactor", "RiskFactors"],
        }),
    }); },
});
exports.useCreateRiskFactorMutation = exports.riskFactorApi.useCreateRiskFactorMutation, exports.useDeleteRiskFactorMutation = exports.riskFactorApi.useDeleteRiskFactorMutation, exports.useFetchRiskFactorQuery = exports.riskFactorApi.useFetchRiskFactorQuery, exports.useFetchRiskFactorsQuery = exports.riskFactorApi.useFetchRiskFactorsQuery, exports.useFetchRiskFactorValueTypesQuery = exports.riskFactorApi.useFetchRiskFactorValueTypesQuery, exports.useUpdateRiskFactorMutation = exports.riskFactorApi.useUpdateRiskFactorMutation;
