"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFetchMdmMessageQuery = exports.systemApi = void 0;
var react_1 = require("@reduxjs/toolkit/query/react");
var baseQuery_1 = require("../../../../api/baseQuery");
var helpers_1 = require("../../domain/helpers");
exports.systemApi = (0, react_1.createApi)({
    reducerPath: "system",
    baseQuery: baseQuery_1.baseQuery,
    tagTypes: ["System"],
    endpoints: function (builder) { return ({
        fetchMdmMessage: builder.query({
            query: function () { return ({
                url: "/api/v1/system/message-mdm",
            }); },
            providesTags: ["System"],
            transformResponse: function (result) {
                return result.payload.map(function (v) { return (__assign(__assign({}, v), (0, helpers_1.getExternalMdmMessageData)(v.data))); });
            },
        }),
    }); },
});
exports.useFetchMdmMessageQuery = exports.systemApi.useFetchMdmMessageQuery;
