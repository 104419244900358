"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RiskFactorForm = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var yup_1 = require("@hookform/resolvers/yup/dist/yup");
var material_1 = require("@mui/material");
var react_hook_form_1 = require("react-hook-form");
var yup = __importStar(require("yup"));
var constants_1 = require("../../../../../constants");
var S = __importStar(require("../../../../../styles"));
var riskFactorApi_1 = require("../../../store/api/riskFactorApi");
var schema = yup.object({
    code: yup.string().max(256, constants_1.TEXTS.getMaxLength(256)).required(constants_1.TEXTS.requiredField),
    name: yup.string().max(512, constants_1.TEXTS.getMaxLength(512)).required(constants_1.TEXTS.requiredField),
    riskFactorValueTypeId: yup.string().required(constants_1.TEXTS.requiredField),
    isBlocked: yup.boolean().required(),
});
var RiskFactorForm = function (_a) {
    var _b, _c, _d, _e, _f;
    var defaultValues = _a.defaultValues, _g = _a.isDisabled, isDisabled = _g === void 0 ? false : _g, onSubmit = _a.onSubmit;
    var riskFactorValueTypes = (0, riskFactorApi_1.useFetchRiskFactorValueTypesQuery)();
    var _h = (0, react_hook_form_1.useForm)({
        defaultValues: defaultValues,
        mode: "onChange",
        resolver: (0, yup_1.yupResolver)(schema),
    }), control = _h.control, _j = _h.formState, errors = _j.errors, isDirty = _j.isDirty, isValid = _j.isValid, handleSubmit = _h.handleSubmit, register = _h.register, reset = _h.reset;
    var handleRiskFactorSubmit = function (values) {
        onSubmit(values);
        reset(values);
    };
    return ((0, jsx_runtime_1.jsxs)("form", __assign({ onSubmit: handleSubmit(handleRiskFactorSubmit) }, { children: [(0, jsx_runtime_1.jsxs)(S.FieldLayout, __assign({ "data-id": "codeField" }, { children: [(0, jsx_runtime_1.jsx)(S.Label, __assign({ "data-id": "codeLabel" }, { children: "\u041Ao\u0434:" })), (0, jsx_runtime_1.jsx)(S.Field, { children: (0, jsx_runtime_1.jsx)(material_1.TextField, __assign({ fullWidth: true }, register("code"), { error: !!((_b = errors === null || errors === void 0 ? void 0 : errors.code) === null || _b === void 0 ? void 0 : _b.message), helperText: (_c = errors === null || errors === void 0 ? void 0 : errors.code) === null || _c === void 0 ? void 0 : _c.message, variant: "outlined" })) })] })), (0, jsx_runtime_1.jsxs)(S.FieldLayout, __assign({ "data-id": "nameField" }, { children: [(0, jsx_runtime_1.jsx)(S.Label, __assign({ "data-id": "nameLabel" }, { children: "\u041D\u0430\u0438\u043C\u0435\u043D\u043E\u0432\u0430\u043D\u0438\u0435:" })), (0, jsx_runtime_1.jsx)(S.Field, { children: (0, jsx_runtime_1.jsx)(material_1.TextField, __assign({ fullWidth: true, multiline: true }, register("name"), { name: "name", error: !!((_d = errors === null || errors === void 0 ? void 0 : errors.name) === null || _d === void 0 ? void 0 : _d.message), helperText: (_e = errors === null || errors === void 0 ? void 0 : errors.name) === null || _e === void 0 ? void 0 : _e.message, variant: "outlined" })) })] })), (0, jsx_runtime_1.jsxs)(S.FieldLayout, __assign({ "data-id": "riskFactorValueTypeIdField" }, { children: [(0, jsx_runtime_1.jsx)(S.Label, __assign({ "data-id": "riskFactorValueTypeIdLabel" }, { children: "\u0422\u0438\u043F \u0434\u0430\u043D\u043D\u044B\u0445:" })), (0, jsx_runtime_1.jsx)(S.Field, { children: (0, jsx_runtime_1.jsx)(react_hook_form_1.Controller, { control: control, name: "riskFactorValueTypeId", defaultValue: "", render: function (_a) {
                                var _b;
                                var field = _a.field;
                                return ((0, jsx_runtime_1.jsx)(material_1.Select, __assign({ fullWidth: true }, field, { disabled: isDisabled || riskFactorValueTypes.isFetching, placeholder: "\u0412\u044B\u0431\u0435\u0440\u0438\u0442\u0435 \u0442\u0438\u043F" }, { children: (_b = riskFactorValueTypes.data) === null || _b === void 0 ? void 0 : _b.map(function (option) { return ((0, jsx_runtime_1.jsx)(material_1.MenuItem, __assign({ value: option.id }, { children: option.name }), option.id)); }) })));
                            } }) })] })), (0, jsx_runtime_1.jsxs)(S.FieldLayout, __assign({ "data-id": "isBlockedField" }, { children: [(0, jsx_runtime_1.jsx)(S.Label, __assign({ "data-id": "isBlockedLabel" }, { children: "\u0411\u043B\u043E\u043A\u0438\u0440\u0443\u044E\u0449\u0438\u0439:" })), (0, jsx_runtime_1.jsx)(S.Field, { children: (0, jsx_runtime_1.jsx)(react_hook_form_1.Controller, { control: control, defaultValue: (_f = defaultValues === null || defaultValues === void 0 ? void 0 : defaultValues.isBlocked) !== null && _f !== void 0 ? _f : false, name: "isBlocked", render: function (_a) {
                                var field = _a.field;
                                return (0, jsx_runtime_1.jsx)(material_1.Switch, __assign({}, field));
                            } }) })] })), (0, jsx_runtime_1.jsx)(S.ActionsLayout, { children: (0, jsx_runtime_1.jsx)(material_1.Button, __assign({ color: "primary", disabled: !isDirty || !isValid, type: "submit", variant: "contained" }, { children: "\u0421\u043E\u0445\u0440\u0430\u043D\u0438\u0442\u044C" })) })] })));
};
exports.RiskFactorForm = RiskFactorForm;
