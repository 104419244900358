"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFetchConsumersQuery = exports.useFetchConsumerQuery = exports.consumersApi = void 0;
var react_1 = require("@reduxjs/toolkit/query/react");
var baseQuery_1 = require("../../../api/baseQuery");
exports.consumersApi = (0, react_1.createApi)({
    reducerPath: "consumers",
    baseQuery: baseQuery_1.baseQuery,
    tagTypes: ["Consumer", "Consumers"],
    endpoints: function (builder) { return ({
        fetchConsumers: builder.query({
            query: function (params) { return ({
                url: "/api/v1/consumer".concat(params),
            }); },
            providesTags: ["Consumers"],
            transformResponse: function (result) { return ({
                rows: result.payload,
                total: result.meta.total,
            }); },
        }),
        fetchConsumer: builder.query({
            query: function (id) { return ({
                url: "/api/v1/consumer/".concat(id),
            }); },
            providesTags: ["Consumer"],
            transformResponse: function (result) { return result.payload; },
        }),
    }); },
});
exports.useFetchConsumerQuery = exports.consumersApi.useFetchConsumerQuery, exports.useFetchConsumersQuery = exports.consumersApi.useFetchConsumersQuery;
