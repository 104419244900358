"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.riskFactorFiltersValues = void 0;
exports.riskFactorFiltersValues = {
    code: {
        id: "code",
        label: "Код",
        value: "",
    },
    name: {
        id: "name",
        label: "Наименование",
        value: "",
    },
};
