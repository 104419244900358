"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.consumerTerroristsFilters = void 0;
exports.consumerTerroristsFilters = {
    birthDate: {
        id: "birthDate",
        label: "Дата рождения",
        value: "",
    },
    name: {
        id: "name",
        label: "ФИО",
        value: "",
    },
    realEstateName: {
        id: "realEstateName",
        label: "Объект недвижимости",
        value: "",
    },
    riskIndicatorName: {
        id: "riskIndicatorName",
        label: "Признак",
        value: "",
    },
};
