"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.consumersTableFilters = void 0;
exports.consumersTableFilters = {
    name: {
        id: "name",
        label: "ФИО",
        value: "",
    },
    birthDate: {
        id: "birthDate",
        label: "Дата рождения",
        value: "",
    },
};
