"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.alertMessages = exports.TEXTS = exports.IconInfoType = void 0;
var IconInfoType;
(function (IconInfoType) {
    IconInfoType["BlockedContractor"] = "blockedContractor";
    IconInfoType["BlockedConsumer"] = "blockedConsumer";
    IconInfoType["BlockedByRiskFactor"] = "blockedByRiskFactor";
    IconInfoType["BlockedPayRiskFactor"] = "blockedPayRiskFactor";
    IconInfoType["BlockedExpert"] = "blockedExpert";
    IconInfoType["EmptyOgrn"] = "emptyOgrn";
})(IconInfoType = exports.IconInfoType || (exports.IconInfoType = {}));
exports.TEXTS = {
    emptyResults: "Нет значений",
    enterValues: "Введите значение",
    emptyOgrn: "Ошибка интеграции с Контур.Фокус. У контрагента отсутствует ОГРН",
    blockedContractor: "Недобросовестная экспертная организация!",
    blockedConsumer: "Потребитель-террорист!",
    blockedExpert: "Недобросовестный эксперт!",
    blockedByRiskFactor: "Обнаружены события по блокирующим риск-факторам",
    blockedPayRiskFactor: "Этот риск-фактор может заблокировать оплаты контрагенту",
    requiredField: "Поле обязательно для заполнения",
    loadingText: "Загрузка",
    tooManyValues: "Значений, удовлетворяющих вашему запросу слишком много, пожалуйста, введите еще символы",
    uninitialized: "Введите значение",
    invalidEmail: "Введите корректную почту",
    getMaxLength: function (value) { return "\u041F\u0440\u0435\u0432\u044B\u0448\u0435\u043D\u043E \u043C\u0430\u043A\u0441\u0438\u043C\u0430\u043B\u044C\u043D\u043E\u0435 \u043A\u043E\u043B\u0438\u0447\u0435\u0441\u0442\u0432\u043E \u0441\u0438\u043C\u0432\u043E\u043B\u043E\u0432 (".concat(value, ")"); },
};
exports.alertMessages = {
    noApprovers: "Нельзя запустить процесс с этапами без участников",
    successProcess: "Процесс успешно запущен",
    errorProcess: "Произошла ошибка с запуском процесса",
    addRoleSuccess: "Роль успешно добавлена",
    addUserSuccess: "Пользователь успешно создан",
    changeUserSuccess: "Пользователь успешно изменен",
    deleteUserSuccess: "Пользователь успешно удален",
    deleteRoleSuccess: "Роль успешно удалена",
    changeRoleSuccess: "Роль успешно изменена",
    addPermissionSuccess: "Разрешение успешно добавлено",
    deletePermissionSuccess: "Разрешение успешно удалено",
    changeApproverGroupSuccess: "Список согласующих успешно изменен",
    addApproverGroupSuccess: "Список согласующих успешно добавлен",
    deleteApproverGroupSuccess: "Список согласующих успешно удален",
    addRiskFactorSuccess: "Риск-фактор успешно создан",
    changeRiskFactorSuccess: "Риск-фактор успешно изменен",
    deleteRiskFactorSuccess: "Риск-фактор успешно удален",
    changeEventCommentSuccess: "Комментарий успешно изменен",
};
