"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getConsumerRiskIndicatorsColumns = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var helpers_1 = require("../../../../helpers");
var risk_indicator_name_1 = require("../../../ud-ui/components/risk-indicator-name");
var getConsumerRiskIndicatorsColumns = function () { return [
    {
        field: "date",
        filterable: false,
        headerName: "Дата выявления",
        minWidth: 220,
        sortable: false,
        valueFormatter: function (params) { return (0, helpers_1.getFormattedDate)(params.value); },
    },
    {
        field: "name",
        filterable: false,
        flex: 600,
        headerName: "Признак",
        sortable: false,
        renderCell: function (params) { return ((0, jsx_runtime_1.jsx)(risk_indicator_name_1.RiskIndicatorName, { name: params.value, scoringResultId: params.row.scoringResultId, scoringResultType: "contractor" })); },
    },
]; };
exports.getConsumerRiskIndicatorsColumns = getConsumerRiskIndicatorsColumns;
