"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getExpertContractorsColumns = void 0;
var getExpertContractorsColumns = function () { return [
    {
        field: "name",
        filterable: false,
        flex: 500,
        headerName: "Наименование",
        maxWidth: 800,
        minWidth: 220,
    },
    {
        field: "ogrn",
        filterable: false,
        headerName: "ОГРН",
        sortable: false,
        width: 180,
        renderCell: function (params) { var _a; return (_a = params.row.ogrn) !== null && _a !== void 0 ? _a : "Отсутствует ОГРН"; },
    },
    {
        field: "inn",
        filterable: false,
        headerName: "ИНН",
        sortable: false,
        width: 180,
    },
    {
        field: "kpp",
        filterable: false,
        headerName: "КПП",
        sortable: false,
        width: 180,
    },
]; };
exports.getExpertContractorsColumns = getExpertContractorsColumns;
